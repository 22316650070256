import { useState, useEffect, useMemo, useContext } from 'react';
import {
  Flex,
  Box,
  Text,
  Spinner,
  Button,
  Link,
  LinkButton,
  Icon,
  Divider,
  Stack,
  Tooltip,
  Accordion,
  AccordionItem,
  AccordionBody,
  AccordionToggle,
} from '@tonic-ui/react';
import { CaretRightIcon, ChevronLeftIcon, ChevronDownIcon, SVGIcon } from '@tonic-ui/react-icons';
import { Trans, useTranslation } from 'react-i18next';
import BasicInfo from './AssetDrawer/BasicInfo';
import AssetCriticality from './AssetDrawer/AssetCriticality';
import AssetGraphIcons from './AssetDrawer/AssetGraphIcons';
import Drawer from '../../Drawer/Drawer';
import { useGetListAssetGroupCloudAssets } from '../../../services/hooks';
import TruncateWithTooltip from '../../TruncateWithTooltip';
import { getRiskColor, assetsDrawerWidth } from '../../helpers';
import { API } from '../../../services';
import { flattenCloudAssetProfile } from '../../../utils/ui';
import GeneralErrorOverlay from '../../error/GeneralErrorOverlay';
import { AppContext } from '../../../store/store';
import SecurityGroup from '../../../assets/Icons/security-group.svg?react';

export type CloudAssetProfileData = {
  data?: BigTableCloudAssetDetailResponse | undefined;
  latestRiskScore: number | undefined;
  assetProfile: BigTableCloudAssetProfile & {
    'Last seen'?: string | undefined;
  };
};

type CloudAssetsDetailsDrawerProps = {
  selectedGroup: SvcRisksApi.Schemas.AssetGroup;
  isOpen: boolean;
  onClose: () => void;
};

type CloudAssetsDetailsDrawerBodyProps = {
  selectedGroup: SvcRisksApi.Schemas.AssetGroup;
  paginatedSelectedGroupAssets: SvcRisksApi.Schemas.GroupAsset[];
  closeDrawer: () => void;
  setCurrentScreen: (currentScreen: string) => void;
  setSelectedCloudAsset: (selectedCloudAsset: SvcRisksApi.Schemas.GroupAsset) => void;
  isAssetSupported: boolean;
  supportedAssetList: string[];
};

type CloudAssetInfoProps = {
  assetID: string;
  riskScore: number | undefined;
  isAssetSupported: boolean;
};

const getIsAssetGraphSupportedType = (supportedAssetList: string[], assetType: string) => {
  return !!assetType ? !!supportedAssetList?.includes(assetType) : false;
};

const CloudAssetInfo = (props: CloudAssetInfoProps) => {
  const { t } = useTranslation();
  const { assetID, riskScore, isAssetSupported } = props;
  const riskColor = getRiskColor(riskScore);

  return (
    <>
      <Text color={riskColor} flex="0 0 90px" textAlign="right" marginRight="4x">
        {riskScore ?? '-'}
      </Text>
      <Box>
        <Tooltip
          label={
            isAssetSupported && assetID
              ? t('riskGraph.assetsDetailsDrawer.tooltips.supportedAssetLink')
              : t('riskGraph.assetsDetailsDrawer.tooltips.unsupportedAssetLink')
          }
          placement="top"
          backgroundColor="gray:80"
          color="white:secondary"
        >
          {isAssetSupported && assetID ? (
            <Link
              href={`/#/app/asd?assetType=cloudAsset&assetId=${assetID}&tab=asset_graph&from=blank`}
              onClick={(e) => {
                e.preventDefault();
                window.RI_HISTORY?.push(`/es/cloudAsset/${assetID}?tab=asset_graph`);
              }}
            >
              <Icon as={CaretRightIcon} cursor="pointer" color="white:primary" />
            </Link>
          ) : (
            <Icon as={CaretRightIcon} color="gray:60" />
          )}
        </Tooltip>
      </Box>
    </>
  );
};

const CloudAssetsDetailsDrawerAccordionToggle = (props: { index: number }) => {
  const { t } = useTranslation();
  const { index } = props;
  const [isExpanded, setIsExpanded] = useState(index === 0 ? true : false);

  return (
    <Tooltip
      disabled={isExpanded}
      label={t('riskGraph.assetsDetailsDrawer.tooltips.assetDetailsToggleTooltip')}
      placement="right"
      backgroundColor="gray:80"
      color="white:secondary"
    >
      <AccordionToggle onClick={() => setIsExpanded(!isExpanded)}>
        <ChevronDownIcon
          size="4x"
          transform={isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)'}
          transition="all 0.2s"
        />
      </AccordionToggle>
    </Tooltip>
  );
};

const CloudAssetsDetailsDrawerBody = (props: CloudAssetsDetailsDrawerBodyProps) => {
  const {
    paginatedSelectedGroupAssets,
    setCurrentScreen,
    setSelectedCloudAsset,
    isAssetSupported,
    supportedAssetList,
  } = props;
  return (
    <>
      <Flex
        direction="row"
        justifyContent="space-between"
        fontSize="sm"
        color="white:secondary"
        borderBottom="2px solid"
        borderColor="gray:70"
        marginBottom="3x"
      >
        <Text marginY="2x">
          <Trans i18nKey="riskGraph.assetsDetailsDrawer.assetName" />
        </Text>
        <Text marginY="2x">
          <Trans i18nKey="riskGraph.assetsDetailsDrawer.riskScore" />
        </Text>
      </Flex>
      <Accordion>
        {paginatedSelectedGroupAssets?.map((cloudAsset, index) => {
          return (
            <AccordionItem key={cloudAsset.assetID} defaultIsExpanded={index === 0 && true}>
              <Flex
                marginBottom="2x"
                key={cloudAsset.assetID}
                width="100%"
                justifyContent="space-between"
              >
                {!!cloudAsset.securityGroups?.length && (
                  <Box flex="0 0 20px" marginRight="2x">
                    {!!cloudAsset.securityGroups && (
                      <CloudAssetsDetailsDrawerAccordionToggle index={index} />
                    )}
                  </Box>
                )}

                <Box overflow="hidden" flex="1">
                  {cloudAsset.assetID !== '' ? (
                    <LinkButton
                      display="block"
                      textAlign="left"
                      width="100%"
                      onClick={() => {
                        setCurrentScreen('detailed-profile');
                        setSelectedCloudAsset(cloudAsset);
                      }}
                    >
                      <TruncateWithTooltip
                        text={cloudAsset.assetAlias}
                        tooltipStyles={{ PopperProps: { usePortal: true } }}
                      />
                    </LinkButton>
                  ) : (
                    <TruncateWithTooltip
                      text={cloudAsset.assetAlias}
                      tooltipStyles={{ PopperProps: { usePortal: true } }}
                    />
                  )}
                </Box>
                <CloudAssetInfo
                  assetID={cloudAsset.assetID}
                  riskScore={cloudAsset.riskScore}
                  isAssetSupported={isAssetSupported}
                />
              </Flex>
              {!!cloudAsset.securityGroups?.length && (
                <AccordionBody>
                  <Flex
                    gap="2x"
                    padding="2x"
                    paddingLeft="10x"
                    paddingBottom="4x"
                    paddingRight="0"
                    flexDirection="column"
                  >
                    {cloudAsset.securityGroups?.map((securityGroup) => {
                      return (
                        <Flex
                          gap="1x"
                          key={securityGroup.sgAssetID}
                          color="white:secondary"
                          alignItems="center"
                        >
                          <SVGIcon width={22} height={22}>
                            <SecurityGroup />
                          </SVGIcon>

                          {securityGroup.sgAssetID && securityGroup.sgAssetID !== '' ? (
                            <LinkButton
                              display="block"
                              textAlign="left"
                              width="100%"
                              minWidth="0"
                              onClick={() => {
                                setCurrentScreen('detailed-profile');
                                setSelectedCloudAsset({
                                  assetID: securityGroup.sgAssetID!,
                                  assetAlias: securityGroup.sgName,
                                  assetType: securityGroup.sgAssetType,
                                  riskScore: securityGroup.sgRiskScore,
                                });
                              }}
                            >
                              <TruncateWithTooltip
                                text={securityGroup.sgName || securityGroup.sgID || ''}
                                tooltipStyles={{ PopperProps: { usePortal: true } }}
                              />
                            </LinkButton>
                          ) : (
                            <TruncateWithTooltip
                              text={securityGroup.sgName || securityGroup.sgID || ''}
                              tooltipStyles={{ PopperProps: { usePortal: true } }}
                            />
                          )}

                          <CloudAssetInfo
                            assetID={securityGroup.sgAssetID!}
                            riskScore={securityGroup.sgRiskScore}
                            isAssetSupported={getIsAssetGraphSupportedType(
                              supportedAssetList,
                              securityGroup.sgAssetType
                            )}
                          />
                        </Flex>
                      );
                    })}
                  </Flex>
                </AccordionBody>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    </>
  );
};

const CloudAssetsDetailsDrawer = (props: CloudAssetsDetailsDrawerProps) => {
  const { selectedGroup, isOpen = false, onClose } = props;
  const [isInitialCall, setIsInitialCall] = useState(true);

  const [currentScreen, setCurrentScreen] = useState('main');
  const [selectedCloudAsset, setSelectedCloudAsset] = useState<
    SvcRisksApi.Schemas.GroupAsset | undefined
  >();

  const [{ selectedAccount }] = useContext(AppContext);
  const { isLoading: criticalityInfoIsLoading, data: criticalityInfoData } =
    API.useGetCriticalityInfo(selectedCloudAsset?.assetID);

  const {
    isLoading,
    isError,
    isFetching,
    data: groupAssets,
    fetchNextPage,
    hasFetchedAllPages,
    remove,
  } = useGetListAssetGroupCloudAssets({
    provider: selectedAccount?.accountProvider,
    accountID: selectedAccount?.accountID,
    groupID: selectedGroup?.groupID,
    region: selectedAccount?.accountRegion,
    limit: 30,
  });

  const { data: cloudAssetGraphDefinitions, isLoading: cloudAssetGraphDefinitionsIsLoading } =
    API.useGetAssetGraphDefinitions();

  const { data: riskHistory, isLoading: riskHistoryIsLoading } = API.useGetRiskHistory({
    assetId: selectedCloudAsset?.assetID,
  });
  const { data: cloudAssetDetail, isLoading: cloudAssetDetailIsLoading } = API.useGetAssetDetail({
    id: selectedCloudAsset?.assetID,
  });
  const { data: assetProfile, isLoading: assetProfileIsLoading } = API.useGetAssetProfile({
    assetId: selectedCloudAsset?.assetID,
  });

  const cloudAssetSummaryDataIsLoading =
    cloudAssetGraphDefinitionsIsLoading || cloudAssetDetailIsLoading || assetProfileIsLoading;

  const flattenedAssetProfileData = useMemo(() => {
    return flattenCloudAssetProfile(assetProfile?.data);
  }, [assetProfile]);

  const latestRiskScore = cloudAssetDetail?.data?.latestRiskScore;

  const cloudAssetProfileData = useMemo(() => {
    return {
      ...cloudAssetDetail,
      latestRiskScore,
      assetProfile: flattenedAssetProfileData,
    };
  }, [cloudAssetDetail, latestRiskScore, flattenedAssetProfileData]);

  const assetGraphSupportedTypeList =
    selectedAccount?.accountProvider === 'AWS'
      ? cloudAssetGraphDefinitions?.data.data.cloudAsset.TypeListForAssetGraphAWS || []
      : selectedAccount?.accountProvider === 'Azure'
        ? cloudAssetGraphDefinitions?.data.data.cloudAsset.TypeListForAssetGraphAzure || []
        : [];
  const isAssetSupported = getIsAssetGraphSupportedType(
    assetGraphSupportedTypeList,
    selectedCloudAsset?.assetType || ''
  );

  const onLoadMoreButtonClick = () => {
    setIsInitialCall(false);
    fetchNextPage();
  };

  const onSwitchToMainButtonClick = () => {
    setCurrentScreen('main');
    setSelectedCloudAsset(undefined);
  };

  const handleDrawerClose = () => {
    remove();
    setIsInitialCall(true);
    onClose();
  };

  useEffect(() => {
    setCurrentScreen('main');
  }, [selectedGroup]);

  if (!isOpen) return <></>;

  const mainContent = {
    header: selectedGroup?.groupLabel,
    body: isLoading ? (
      <Flex height="100%" alignItems="center" justifyContent="center">
        <Spinner />
      </Flex>
    ) : isError ? (
      <GeneralErrorOverlay compact={true} />
    ) : groupAssets?.length ? (
      <CloudAssetsDetailsDrawerBody
        selectedGroup={selectedGroup}
        paginatedSelectedGroupAssets={groupAssets}
        closeDrawer={handleDrawerClose}
        setCurrentScreen={setCurrentScreen}
        setSelectedCloudAsset={setSelectedCloudAsset}
        isAssetSupported={isAssetSupported}
        supportedAssetList={assetGraphSupportedTypeList || []}
      />
    ) : (
      <CloudAssetsDetailsDrawerBody
        selectedGroup={selectedGroup}
        paginatedSelectedGroupAssets={[
          {
            assetID: '',
            assetAlias: selectedGroup.groupLabel,
            riskScore: selectedGroup.riskiestAssetScore,
            assetType: selectedGroup.serviceType,
            serviceName: selectedGroup.serviceName ?? '',
          },
        ]}
        setCurrentScreen={setCurrentScreen}
        setSelectedCloudAsset={setSelectedCloudAsset}
        closeDrawer={handleDrawerClose}
        isAssetSupported={isAssetSupported}
        supportedAssetList={assetGraphSupportedTypeList || []}
      />
    ),
    footer: (
      <Box margin="0 auto">
        {!isError && !isLoading && !(isInitialCall && hasFetchedAllPages) && (
          <Button
            data-id="load-more-button"
            data-track="REX_cloudAssetsDrawer_loadMore"
            variant="ghost"
            onClick={onLoadMoreButtonClick}
            disabled={hasFetchedAllPages || isFetching}
            paddingY="6px"
          >
            <Trans i18nKey="riskGraph.assetsDetailsDrawer.loadMore" />
          </Button>
        )}
      </Box>
    ),
  };

  const detailedProfile = {
    header: (
      <Flex>
        <Button
          data-id="back-to-cloud-assets-button"
          variant="ghost"
          size="sm"
          paddingLeft="0"
          paddingRight="2x"
          onClick={onSwitchToMainButtonClick}
        >
          <Icon as={ChevronLeftIcon} />
        </Button>
        <Text>
          <Trans i18nKey="riskGraph.assetsDetailsDrawer.detailedProfile" />
        </Text>
      </Flex>
    ),
    body: cloudAssetSummaryDataIsLoading ? (
      <Flex height="100%" alignItems="center" justifyContent="center">
        <Spinner />
      </Flex>
    ) : (
      selectedCloudAsset && (
        <Stack>
          <Flex justifyContent="space-between" width="100%">
            <AssetGraphIcons
              width="16px"
              height="16px"
              category={cloudAssetDetail?.data.category}
            />
            <Box overflow="hidden" flex="1" marginX="3x">
              <TruncateWithTooltip
                text={selectedCloudAsset.assetAlias}
                tooltipStyles={{ PopperProps: { usePortal: true } }}
              />
            </Box>
            <Box>
              {isAssetSupported ? (
                <Link
                  href={`/#/app/asd?assetType=cloudAsset&assetId=${selectedCloudAsset.assetID}&tab=asset_graph&from=blank`}
                  display="block"
                  onClick={(e) => {
                    e.preventDefault();
                    window.RI_HISTORY?.push(
                      `/es/cloudAsset/${selectedCloudAsset.assetID}?tab=asset_graph`
                    );
                  }}
                >
                  <Trans i18nKey={'riskGraph.assetsDetailsDrawer.viewAssetGraph'} />
                </Link>
              ) : (
                <Link
                  href={`/#/app/asd?assetType=cloudAsset&assetId=${selectedCloudAsset.assetID}&from=blank`}
                  display="block"
                  onClick={(e) => {
                    e.preventDefault();
                    window.RI_HISTORY?.push(`/es/cloudAsset/${selectedCloudAsset.assetID}`);
                  }}
                >
                  <Trans i18nKey={'riskGraph.assetsDetailsDrawer.viewRiskAssessment'} />
                </Link>
              )}
            </Box>
          </Flex>
          <BasicInfo
            cloudAssetProfileData={cloudAssetProfileData}
            riskIndexLineData={riskHistory}
            riskHistoryIsLoading={riskHistoryIsLoading}
          />
          <Divider variant="dashed" />
          <AssetCriticality
            loading={criticalityInfoIsLoading}
            data={criticalityInfoData?.data.data}
            assetID={selectedCloudAsset.assetID}
          />
        </Stack>
      )
    ),
  };

  return (
    <Drawer
      id="cloud-assets-details-drawer"
      isOpen={isOpen}
      onClose={handleDrawerClose}
      size={assetsDrawerWidth}
      placement="right"
      {...(currentScreen === 'main' ? mainContent : detailedProfile)}
    />
  );
};

CloudAssetsDetailsDrawer.displayName = 'CloudAssetsDetailsDrawer';
export default CloudAssetsDetailsDrawer;
