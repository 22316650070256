import { useContext } from 'react';
import { Button, Flex, Text, useTheme } from '@tonic-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import CloudAccountSelectionControl from '../components/CloudAccountSelectionControl';
import { graphBGColorCode } from '../components/CloudAssetsGraph/helpers';
import CollapseIcon from '../assets/Icons/collapse.svg?react';
import ExpandIcon from '../assets/Icons/expand.svg?react';
import Switch from '../components/Switch';
import LastUpdated from '../components/LastUpdated';
import { AppContext } from '../store/store';

const GraphHeaderControls = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const [{ isGraphFullscreen, isRiskFocusViewActive, isGraphLoading }, dispatchGlobalStoreEvent] =
    useContext(AppContext);

  const { colors } = theme;

  return (
    <>
      <CloudAccountSelectionControl />
      <Flex
        flexDirection="column"
        alignItems="flex-end"
        position="absolute"
        background="gray:100"
        top="4x"
        right="1px"
        gap="2x"
        {...(isGraphFullscreen && { right: '4x' })}
      >
        <Flex gap="3x">
          <Switch
            id="cloud-assets-graph-focused-view-toggle"
            data-track="REX_focusedView_toggle"
            data-id="REX_focusedView_toggle"
            label={t('focusedView')}
            checked={isRiskFocusViewActive}
            onChange={(e) => {
              dispatchGlobalStoreEvent({
                type: 'SET_RISK_FOCUSED_VIEW',
                payload: e.target.checked,
              });
            }}
            disabled={isGraphLoading}
          />
          <Button
            data-id="cloud-assets-graph-expand-button"
            data-track="REX_graphButton_expand"
            onClick={() => {
              dispatchGlobalStoreEvent({
                type: 'SET_FULLSCREEN',
                payload: !isGraphFullscreen,
              });
            }}
            variant="secondary"
            backgroundColor={graphBGColorCode}
            paddingLeft="2x"
            paddingRight="2x"
            {...(isGraphFullscreen && {
              'data-track': 'REX_graphButton_collapse',
              backgroundColor: 'blue:60',
              variant: 'primary',
            })}
          >
            {isGraphFullscreen ? (
              <CollapseIcon fill={colors['white:emphasis']} width="14px" height="14px" />
            ) : (
              <ExpandIcon fill="currentColor" width="14px" height="14px" />
            )}
            <Text paddingLeft="2x">
              <Trans i18nKey={isGraphFullscreen ? 'collapse' : 'expand'} />
            </Text>
          </Button>
        </Flex>
        <LastUpdated />
      </Flex>
    </>
  );
};

export default GraphHeaderControls;
