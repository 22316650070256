import { Stack, Text } from '@tonic-ui/react';
import { Trans } from 'react-i18next';
import { ErrorType, generateErrorDescription } from '../../utils/errors';
import { APIErrorStatus } from '../../services/hooks/util';
import AccessDeniedSVG from '../../assets/Icons/access-denied.svg?react';

type AccountsAPIErrorOverlayProps = {
  error: unknown;
};

const AccountsAPIErrorOverlay = (props: AccountsAPIErrorOverlayProps) => {
  const { error } = props;
  const apiError = error as APIErrorStatus;
  if (!apiError.errorType) {
    // Let general error boundary handle unknown errors
    throw error;
  }
  return (
    <Stack height="100%" alignItems="center" justifyContent="center" spacing="2x">
      {apiError.status === 403 && (
        <>
          <AccessDeniedSVG width="192px" height="192px" />
          <Text
            size="xl"
            fontWeight="semibold"
            marginTop="4x"
            marginBottom="2x"
            lineHeight="sm"
            color="white:emphasis"
          >
            <Trans i18nKey="restrictedAccessOverlay.title" />
          </Text>
          <Text
            width="600px"
            size="sm"
            marginBottom="2x"
            lineHeight="sm"
            color="white:secondary"
            textAlign="center"
          >
            <Trans i18nKey="restrictedAccessOverlay.subtitle" />
          </Text>
        </>
      )}
      {((!(apiError.status === 403) && apiError.errorType === ErrorType.CLOUD_PROVIDERS) ||
        apiError.errorType === ErrorType.ACCOUNTS) && (
        <>
          <Text textAlign="center" size="lg" color="white:primary">
            <Trans i18nKey="riskGraph.error.message" />
          </Text>
          <Text textAlign="center" size="sm" color="white:secondary">
            <Trans i18nKey="riskGraph.error.graphAPIError" />
          </Text>
          <Text textAlign="center" size="sm" color="white:secondary">
            {generateErrorDescription(apiError.status as number)}
          </Text>
        </>
      )}
    </Stack>
  );
};

AccountsAPIErrorOverlay.displayName = 'AccountsAPIErrorOverlay';
export default AccountsAPIErrorOverlay;
