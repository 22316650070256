import { AxiosRequestConfig } from 'axios';
import { axios } from './instance';

export const getListAssetGroupCloudAssets = async (
  {
    provider,
    accountID,
    groupID,
  }: {
    provider: SvcRisksApi.Parameters.Provider | undefined;
    accountID: SvcRisksApi.Parameters.Account | undefined;
    groupID: SvcRisksApi.Parameters.GroupID | undefined;
  },
  searchParams: {
    region: SvcRisksApi.Parameters.Region | undefined;
    limit?: SvcRisksApi.Parameters.LimitParam;
    cursor: SvcRisksApi.Parameters.CursorParam;
    graphType?: SvcRisksApi.Parameters.GraphType;
  },
  requestConfig?: AxiosRequestConfig
) => {
  return await axios.get<SvcRisksApiPaths.ListAssets.Responses.$200>(
    `/internal/v2/direct/rex/api/cloud-providers/${provider}/accounts/${accountID}/asset-groups/${groupID}/assets`,
    {
      ...requestConfig,
      params: searchParams,
    }
  );
};

export const getListAssetGroups = async (
  {
    provider,
    accountID,
  }: {
    provider: SvcRisksApi.Parameters.Provider | undefined;
    accountID: SvcRisksApi.Parameters.Account | undefined;
  },
  searchParams: {
    region: SvcRisksApi.Parameters.Region | undefined;
  },
  requestConfig?: AxiosRequestConfig
) => {
  const axiosResponse = await axios.get<SvcRisksApiPaths.ListAssetGroups.Responses.$200>(
    `/internal/v2/direct/rex/api/cloud-providers/${provider}/accounts/${accountID}/asset-groups`,
    {
      ...requestConfig,
      params: searchParams,
    }
  );
  return {
    ...axiosResponse,
    data: axiosResponse.data,
  };
};

const sumNumberOfCloudAccounts = (data: SvcRisksApi.Schemas.CloudProvidersResponse) => {
  return data?.reduce(
    (total, cloudProvider) => total + (cloudProvider.numberOfCloudAccounts ?? 0),
    0
  );
};

export const getListCloudProviders = async (requestConfig?: AxiosRequestConfig) => {
  const axiosResponse = await axios.get<SvcRisksApiPaths.ListCloudProviders.Responses.$200>(
    `/internal/v2/direct/rex/api/cloud-providers`,
    requestConfig
  );

  if (axiosResponse?.data?.length > 1) {
    axiosResponse.data.unshift({
      cloudProvider: 'All',
      numberOfCloudAccounts: sumNumberOfCloudAccounts(axiosResponse.data),
    });
    return axiosResponse;
  } else {
    axiosResponse.data = axiosResponse.data?.filter((provider) =>
      ['Azure', 'AWS', 'GCP'].includes(provider.cloudProvider ?? '')
    );
    return axiosResponse;
  }
};

export const getListAccounts = async (
  { provider }: { provider: SvcRisksApi.Parameters.Provider },
  requestConfig?: AxiosRequestConfig
) => {
  const axiosResponse = await axios.get<SvcRisksApi.AccountResponse>(
    `/internal/v2/direct/rex/api/cloud-providers/${provider}/accounts`,
    {
      ...requestConfig,
    }
  );
  axiosResponse.data?.forEach((account) => {
    account.provider = provider;
  });
  return axiosResponse;
};

export const getAccountDetails = async (
  {
    provider,
    accountID,
  }: {
    provider: SvcRisksApi.Parameters.Provider | undefined;
    accountID: SvcRisksApi.Parameters.Account | undefined;
  },
  requestConfig?: AxiosRequestConfig
) => {
  return await axios.get<SvcRisksApi.Schemas.AccountDetails>(
    `/internal/v2/direct/rex/api/cloud-providers/${provider}/accounts/${accountID}`,
    requestConfig
  );
};

export const modifyAssetGroup = async (
  {
    provider,
    accountID,
    groupID,
  }: {
    provider: SvcRisksApi.Parameters.Provider;
    accountID: SvcRisksApi.Parameters.Account;
    groupID: SvcRisksApi.Parameters.GroupID;
  },
  payload: SvcRisksApiPaths.ModifyAssetGroup.RequestBody,
  requestConfig?: AxiosRequestConfig
) => {
  return await axios.post<SvcRisksApiPaths.ModifyAssetGroup.Responses.$200>(
    `/internal/v2/direct/rex/api/cloud-providers/${provider}/accounts/${accountID}/asset-groups/${groupID}`,
    payload,
    requestConfig
  );
};
