import { useMemo } from 'react';
import HighchartsReactOfficial from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { Global, css } from '@emotion/react';
import { ceilRiskScore } from '../../../../utils/ui';

type LineChartProps = {
  data: RiskHistoryResponse['data'];
};

const lineColor = 'rgba(255,255,255,0.92)';

const LineChart = (props: LineChartProps) => {
  const { data } = props;

  const lineData = useMemo(() => {
    return data.map((item) => ({
      x: item.timestamp,
      y: ceilRiskScore(item.riskScore),
    }));
  }, [data]);

  const options = useMemo(() => {
    return {
      chart: {
        backgroundColor: '#212121',
        height: 88,
      },
      credits: {
        enabled: false,
      },
      title: null,
      xAxis: {
        visible: false,
        type: 'datetime',
      },
      accessibility: {
        // if true, we need to include the "accessibility.js" module
        enabled: false,
      },
      yAxis: {
        className: 'yAxisRisk',
        tickPositions: [0, 70, 100],
        gridLineColor: '#424242',
        lineWidth: 0,
        title: {
          enabled: false,
        },
        labels: {
          style: {
            color: 'rgba(255, 255, 255, .47)',
            fontSize: '0.75rem',
          },
        },
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        style: {
          color: 'rgba(255, 255, 255, .47)',
          fontSize: '0.75rem',
        },
        xDateFormat: '%Y-%m-%d %H:%M:%S',
        pointFormat: '',
        crosshairs: true,
      },
      plotOptions: {
        area: {
          lineWidth: 1,
          marker: {
            enabled: false,
          },
        },
      },
      series: [
        {
          type: 'area',
          color: lineColor,
          fillColor: 'transparent',
          data: lineData,
          pointStart: lineData.length > 0 ? lineData[0].x : new Date().valueOf(),
        },
      ],
    };
  }, [lineData]);

  return (
    <>
      <Global
        styles={css`
          .yAxisRisk {
            .highcharts-grid-line:nth-of-type(2) {
              stroke: rgba(229, 38, 48, 0.5);
            }
            text:nth-of-type(2) {
              fill: #b80003 !important;
            }
          }
        `}
      />
      <HighchartsReactOfficial highcharts={Highcharts} options={options} />
    </>
  );
};

LineChart.displayName = 'LineChart';
export default LineChart;
