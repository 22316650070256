import {
  getGridElementLevelByGroupId,
  getNodeLabelHeight,
  getParentNodeLabelHeight,
} from '../helpers';

const getEdgeGroupID = (edge: SvcRisksApi.Schemas.Link, end: LinkEnd) => {
  return edge[end === 'source' ? 'sourceGroupID' : 'targetGroupID'];
};

export const findEdgeInList = (link: GraphLink, edgeList: SvcRisksApi.Schemas.Link[]) => {
  return !!edgeList.find(
    (relatedEdge) =>
      relatedEdge.sourceGroupID === link.source.groupID &&
      relatedEdge.targetGroupID === link.target.groupID
  );
};

const findEdgesByGroupId = (
  groupId: number,
  end: LinkEnd,
  edgeList: SvcRisksApi.Schemas.Link[]
) => {
  return edgeList.filter((edge) => {
    const edgeGroupId = getEdgeGroupID(edge, end);
    return edgeGroupId === groupId;
  });
};

export const findRelatedEdgesByGroupId = (
  groupdId: number,
  edgeList: SvcRisksApi.Schemas.Link[]
): SvcRisksApi.Schemas.Link[] => {
  const relatedEdges: SvcRisksApi.Schemas.Link[] = [];

  ['leadingTo', 'goingFrom'].forEach((edgeLevel) => {
    let edgesRelatedToNode = edgeList.filter((edge) => {
      const edgeEnd = edgeLevel === 'leadingTo' ? 'target' : 'source';
      const edgeGroupId = getEdgeGroupID(edge, edgeEnd);
      return edgeGroupId === groupdId;
    });
    while (edgesRelatedToNode?.length) {
      let newRelatedEdges: SvcRisksApi.Schemas.Link[] = [];
      edgesRelatedToNode.forEach((edge) => {
        relatedEdges.push(edge);
        const edgeEnd = edgeLevel === 'leadingTo' ? 'source' : 'target';
        const edgeGroupId = getEdgeGroupID(edge, edgeEnd);
        const edgeNewRelatedEdges = findEdgesByGroupId(
          edgeGroupId,
          edgeLevel === 'leadingTo' ? 'target' : 'source',
          edgeList
        ).filter(
          (edgeNewRelatedEdge) =>
            !relatedEdges.find(
              (relatedEdge) =>
                relatedEdge.sourceGroupID === edgeNewRelatedEdge.sourceGroupID &&
                relatedEdge.targetGroupID === edgeNewRelatedEdge.targetGroupID
            )
        );
        newRelatedEdges = newRelatedEdges.concat(edgeNewRelatedEdges);
      });
      edgesRelatedToNode = newRelatedEdges;
    }
  });

  return relatedEdges;
};

export const getNodeLinkTouchPoint = (
  linkIsSecondary: boolean,
  linkIsBetweenSiblingsSubnets: boolean,
  end: 'source' | 'target',
  graphOrientation: 'horizontal' | 'vertical',
  isLinkOnXSameLevel: boolean,
  isLinkOnYSameLevel: boolean,
  linkXDirection: string,
  linkYDirection: string,
  isLinkInsideSameParent: boolean,
  sourceNode: GraphNode,
  targetNode: GraphNode
) => {
  if (!linkIsSecondary && !linkIsBetweenSiblingsSubnets) {
    if (graphOrientation === 'horizontal') {
      return end === 'source' || isLinkOnXSameLevel || linkXDirection === 'left' ? 'right' : 'left';
    } else {
      return end === 'source' || isLinkOnYSameLevel || linkYDirection === 'top' ? 'bottom' : 'top';
    }
  } else {
    if (isLinkInsideSameParent || linkIsBetweenSiblingsSubnets) {
      if (isLinkOnXSameLevel) {
        if (
          Math.abs(sourceNode.colIndex - targetNode.colIndex) === 1 ||
          linkIsBetweenSiblingsSubnets
        ) {
          // we have vertically adjascent nodes, use direct edge
          if (linkYDirection === 'bottom') {
            return end === 'source' ? 'bottom' : 'top';
          } else {
            return end === 'source' ? 'top' : 'bottom';
          }
        } else {
          // we dont have vertically adjascent nodes, use custom path edge
          return 'left';
        }
      } else if (isLinkOnYSameLevel) {
        if (
          Math.abs(sourceNode.rowIndex - targetNode.rowIndex) === 1 ||
          linkIsBetweenSiblingsSubnets
        ) {
          // we have horizontally adjascent nodes, use direct edge
          if (linkXDirection === 'right') {
            return end === 'source' ? 'right' : 'left';
          } else {
            return end === 'source' ? 'left' : 'right';
          }
        } else {
          // we dont have horizontally adjascent nodes, use custom path edge
          return 'top';
        }
      } else {
        // We have a diagonal.
        if (linkXDirection === 'right') {
          return end === 'source' ? 'right' : 'left';
        } else {
          return end === 'source' ? 'left' : 'right';
        }
      }
    } else {
      if (end === 'source') {
        if (isLinkOnYSameLevel) {
          return 'top';
        } else if (isLinkOnXSameLevel) {
          return 'right';
        } else {
          return linkXDirection === 'none' ? 'right' : linkXDirection;
        }
      } else {
        if (isLinkOnYSameLevel) {
          return 'top';
        } else if (isLinkOnXSameLevel) {
          return 'right';
        } else {
          return linkXDirection === 'right' ? 'left' : 'right';
        }
      }
    }
  }
};

export const getLinkXDirection = (
  isLinkOnXSameLevel: boolean,
  sourceNodeX: number,
  targetNodeX: number
) => {
  return isLinkOnXSameLevel ? 'none' : targetNodeX > sourceNodeX ? 'right' : 'left';
};

export const getLinkYDirection = (
  isLinkOnYSameLevel: boolean,
  sourceNodeY: number,
  targetNodeY: number
) => {
  return isLinkOnYSameLevel ? 'none' : targetNodeY > sourceNodeY ? 'bottom' : 'top';
};

export const getNodeTouchPointCoordinates = (
  node: GraphNode,
  touchPoint: string,
  offset: number
) => {
  return {
    x: touchPoint === 'left' ? node.x - offset : touchPoint === 'right' ? node.x + offset : node.x,
    y: touchPoint === 'top' ? node.y - offset : touchPoint === 'bottom' ? node.y + offset : node.y,
  };
};

const getLinkDirection = (
  sourceID: number,
  targetID: number,
  gridSystem: GraphGrid,
  flipGraphOrientation: boolean
): 'backwards' | 'forward' => {
  const graphOrientation = flipGraphOrientation ? 'vertical' : 'horizontal';
  const sourceLevel = getGridElementLevelByGroupId(sourceID, gridSystem, graphOrientation);
  const targetLevel = getGridElementLevelByGroupId(targetID, gridSystem, graphOrientation);

  return targetLevel < sourceLevel ? 'backwards' : 'forward';
};

const getLinkCustomPathOffsetCoordinates = (
  sameLevelAxis: 'x' | 'y',
  linkDirection: string,
  offsetType: LinkEnd,
  sourceX: number,
  sourceY: number,
  offset: number,
  nodeTouchPoint: string
) => {
  if (sameLevelAxis === 'x') {
    if (linkDirection === 'top') {
      return {
        x: nodeTouchPoint === 'left' ? sourceX - offset * 2 : sourceX + offset * 2,
        y: offsetType === 'source' ? sourceY - offset * 2 : sourceY + offset * 2,
      };
    } else {
      return {
        x: nodeTouchPoint === 'left' ? sourceX - offset * 2 : sourceX + offset * 2,
        y: offsetType === 'source' ? sourceY : sourceY,
      };
    }
  } else {
    if (linkDirection === 'left') {
      return {
        x: offsetType === 'source' ? sourceX - offset * 2 : sourceX + offset * 2,
        y: nodeTouchPoint === 'top' ? sourceY - offset * 2 : sourceY + offset * 2,
      };
    } else {
      return {
        x: offsetType === 'source' ? sourceX + offset * 2 : sourceX - offset * 2,
        y: nodeTouchPoint === 'top' ? sourceY - offset * 2 : sourceY + offset * 2,
      };
    }
  }
};

export const getCustomPathPoints = (
  isLinkSecondary: boolean,
  sourceTouchPoints: Coordinates,
  targetTouchPoints: Coordinates,
  sourceOffset: number,
  targetOffset: number,
  isGraphHorizontal: boolean,
  LABEL_WIDTH: number,
  LABEL_HEIGHT: number,
  linkIsOnSameXLevel: boolean,
  linkXDirection: string,
  linkYDirection: string,
  sourceNodeTouchPoint: string,
  targetNodeTouchPoint: string,
  X_GAP: number,
  Y_GAP: number
) => {
  const sourceX = sourceTouchPoints.x;
  const sourceY = sourceTouchPoints.y;
  const targetX = targetTouchPoints.x;
  const targetY = targetTouchPoints.y;

  if (isLinkSecondary) {
    const linkDirection = linkXDirection !== 'none' ? linkXDirection : linkYDirection;

    return {
      startPoint: {
        x: sourceX,
        y: sourceY,
      },
      startMiddlePoint: getLinkCustomPathOffsetCoordinates(
        linkIsOnSameXLevel ? 'x' : 'y',
        linkDirection,
        'source',
        sourceX,
        sourceY,
        sourceOffset,
        sourceNodeTouchPoint
      ),
      endMiddlePoint: getLinkCustomPathOffsetCoordinates(
        linkIsOnSameXLevel ? 'x' : 'y',
        linkDirection,
        'target',
        targetX,
        targetY,
        targetOffset,
        targetNodeTouchPoint
      ),
      endPoint: {
        x: targetX,
        y: targetY,
      },
    };
  } else {
    const totalXOffset = X_GAP / 2 + LABEL_WIDTH;
    const totalYOffset = Y_GAP / 2 + LABEL_HEIGHT;

    return {
      startPoint: {
        x: sourceX,
        y: sourceY,
      },
      startMiddlePoint: {
        x: isGraphHorizontal
          ? sourceX + totalXOffset
          : linkXDirection === 'right'
            ? sourceX + totalYOffset
            : sourceX - totalYOffset,
        y: isGraphHorizontal
          ? linkYDirection === 'bottom'
            ? sourceY + totalYOffset
            : sourceY - totalYOffset
          : sourceY + totalXOffset,
      },
      endMiddlePoint: {
        x: isGraphHorizontal
          ? sourceX + totalXOffset
          : linkXDirection === 'right'
            ? targetX - totalYOffset
            : targetX + totalYOffset,
        y: isGraphHorizontal
          ? linkYDirection === 'bottom'
            ? targetY - totalYOffset
            : targetY + totalYOffset
          : sourceY + totalXOffset,
      },
      endPoint: {
        x: !isGraphHorizontal
          ? linkXDirection === 'right'
            ? targetX - 20
            : targetX + 20
          : targetX,
        y: isGraphHorizontal ? (linkYDirection === 'top' ? targetY + 20 : targetY - 20) : targetY,
      },
    };
  }
};

export const getLinkOffset = (
  node: GraphNode,
  graphOrientation: GraphOrientation,
  nodeRadius: number,
  nodeLinkTouchPoint: string,
  zoomLevel: number
) => {
  if (graphOrientation === 'vertical') {
    if (!!node.childNodes?.length) {
      // We have a subnet (parent group).
      if (nodeLinkTouchPoint === 'top') {
        // Add space to account for parent group node top label.
        return node.nodeHeight / 2 + getParentNodeLabelHeight(zoomLevel) / 2;
      } else if (nodeLinkTouchPoint === 'bottom') {
        return node.nodeHeight / 2;
      } else {
        return node.nodeWidth / 2;
      }
    } else {
      // We have a single node
      if (nodeLinkTouchPoint === 'bottom') {
        // Add space to account for node bottom label.
        return nodeRadius + getNodeLabelHeight(zoomLevel);
      } else {
        return nodeRadius;
      }
    }
  } else {
    if (!!node.childNodes?.length) {
      // We have a subnet (parent group).
      if (nodeLinkTouchPoint === 'left' || nodeLinkTouchPoint === 'right') {
        return node.nodeWidth / 2;
      } else if (nodeLinkTouchPoint === 'top') {
        return node.nodeHeight / 2 + getNodeLabelHeight(zoomLevel);
      } else {
        return node.nodeHeight / 2;
      }
    } else {
      // We have a single node
      if (nodeLinkTouchPoint === 'bottom') {
        // Add space to account for node bottom label.
        return nodeRadius + getNodeLabelHeight(zoomLevel);
      } else {
        return nodeRadius;
      }
    }
  }
};

export const augmentLinksDataForGridGraphViz = (
  links: SvcRisksApi.Schemas.Link[],
  augmentedNodes: GraphNode[],
  flipGraphOrientation: boolean,
  gridSystem: GraphGrid,
  linksAreSecurityGroup?: boolean
): GraphLink[] => {
  // Filter links with source or target groupID that are not found in the grid sent by the API or that are children nodes.
  const filteredd3links: SvcRisksApi.Schemas.Link[] = links.filter((link) => {
    const sourceNode = augmentedNodes.find((node: any) => node.groupID === link.sourceGroupID);
    const targetNode = augmentedNodes.find((node: any) => node.groupID === link.targetGroupID);
    return sourceNode && targetNode;
  });
  const d3links: GraphLink[] = filteredd3links.map((link, index) => {
    const sourceNode = augmentedNodes.find((node: any) => node.groupID === link.sourceGroupID)!;
    const targetNode = augmentedNodes.find((node: any) => node.groupID === link.targetGroupID)!;

    return {
      source: sourceNode,
      target: targetNode,
      id: index,
      linkType: link.type,
      linkDirection: linksAreSecurityGroup
        ? 'forward'
        : getLinkDirection(
            sourceNode.groupID,
            targetNode.groupID,
            gridSystem,
            flipGraphOrientation
          ),
      targetIsParentGroup: !!targetNode.childNodes?.length,
      assetSecurityGroups: link.assetSecurityGroups,
      endTargetGroupIDs: link.endTargetGroupIDs,
    };
  });
  return d3links;
};

export const getBasicLinksFromAugmentedLinkList = (
  basicLinks: SvcRisksApi.Schemas.Link[],
  augmentedLinks: GraphLink[]
) => {
  return basicLinks.filter((basicLink) =>
    augmentedLinks.find(
      (augmentedLink) =>
        augmentedLink.source.groupID === basicLink.sourceGroupID &&
        augmentedLink.target.groupID === basicLink.targetGroupID
    )
  );
};

export const getEdgeTargetOffsetMultiplierBasedOnSource = (
  grid: GraphGrid,
  sourceGroupId: number,
  targetGroupId: number,
  primaryLinkList: SvcRisksApi.Schemas.Link[]
): number => {
  // Find all edges with current edge target in common.
  const edgesWithSameTarget = primaryLinkList.filter(
    (primaryLink) =>
      primaryLink.targetGroupID === targetGroupId && primaryLink.sourceGroupID !== sourceGroupId
  );

  if (!edgesWithSameTarget.length) {
    // If no edges with current edge target in common, return a multiplier of 0 to center the edge.
    return 0;
  } else {
    // Find current edge target hop and level.
    let currentEdgeTargetHop: number = 0;
    let currentEdgeTargetLevel: number = 0;
    grid.forEach((hop, hopIndex) => {
      if (hop.includes(targetGroupId)) {
        currentEdgeTargetHop = hopIndex;
        currentEdgeTargetLevel = hop.indexOf(targetGroupId);
      }
    });

    // Find current edge source hop and level.
    let currentEdgeSourceHop: number = 0;
    let currentEdgeSourceLevel: number = 0;
    grid.forEach((hop, hopIndex) => {
      if (hop.includes(sourceGroupId)) {
        currentEdgeSourceHop = hopIndex;
        currentEdgeSourceLevel = hop.indexOf(sourceGroupId);
      }
    });

    // Need to find the full width of the grid to place internet related link source.
    const gridMaxWidth = grid.reduce((prev, current) => Math.max(current.length, prev), 0);

    // If current edge is on a lower hop than target hop, add to edge position list.
    // If it is on an equal or higher level, it means the edge will reach the target on the opposite side so we dont worry about it for now.
    const edgesWithSameTargetSourceGridPosition =
      currentEdgeSourceHop < currentEdgeTargetHop
        ? [
            {
              groupId: sourceGroupId,
              level: sourceGroupId === 0 ? gridMaxWidth / 2 - 1 : currentEdgeSourceLevel,
            },
          ]
        : [];

    // Store edges with target in common source grid positions.
    edgesWithSameTarget.forEach((edge) => {
      grid.forEach((hop, hopIndex) => {
        hop.forEach((hopItem, hopItemIndex) => {
          if (hopItem === edge.sourceGroupID && hopIndex < currentEdgeTargetHop) {
            edgesWithSameTargetSourceGridPosition.push({
              groupId: hopItem,
              level: hopItem === 0 ? gridMaxWidth / 2 - 1 : hopItemIndex,
            });
          }
        });
      });
    });
    // Sort edges position list by level.
    edgesWithSameTargetSourceGridPosition.sort((a, b) => a.level - b.level);

    const edgesTargetOffset: any[] = [];
    const edgeSourceLevelAtTargetLevel = edgesWithSameTargetSourceGridPosition.find(
      (edge) => edge.level === currentEdgeTargetLevel
    );

    if (edgeSourceLevelAtTargetLevel) {
      // We have an edge with a source on same level as the common target, set it in the middle with a multiplier of 0.
      edgesTargetOffset.push({
        groupId: edgeSourceLevelAtTargetLevel.groupId,
        multiplier: 0,
      });

      // Get all source group IDs at a lower level than target.
      const levelInferiorToTarget = edgesWithSameTargetSourceGridPosition.slice(
        0,
        edgesWithSameTargetSourceGridPosition.indexOf(edgeSourceLevelAtTargetLevel)
      );
      // Invert the order to add to array of multiplier.
      levelInferiorToTarget
        .sort((a, b) => b.level - a.level)
        .forEach((edge, index) => {
          // TODO: Have this maximum side by side edges calculation done dynamically.
          // This would take the height or width of the target and limit edges based on the value.
          edgesTargetOffset.push({
            groupId: edge.groupId,
            multiplier: ((index >= 3 ? 3 : index) + 1) * -1, // limiting each side of the center edge to 4
          });
        });

      // Get all source group IDs at a superior level than target.
      const levelSuperiorToTarget = edgesWithSameTargetSourceGridPosition
        .slice(edgesWithSameTargetSourceGridPosition.indexOf(edgeSourceLevelAtTargetLevel))
        .slice(1);
      levelSuperiorToTarget.forEach((edge, index) => {
        // TODO: Have this maximum side by side edges calculation done dynamically.
        // This would take the height or width of the target and limit edges based on the value.
        edgesTargetOffset.push({
          groupId: edge.groupId,
          multiplier: ((index >= 3 ? 3 : index) + 1) * 1, // limiting each side of the center edge to 4
        });
      });

      return edgesTargetOffset.find((edge) => edge.groupId === sourceGroupId)?.multiplier || 0;
    } else {
      // We dont have an edge with source and target on same level.
      if (edgesWithSameTargetSourceGridPosition.length <= 1) {
        // If the edge position list length is 0 or 1, return a multiplier of 0 since we'll want to center it.
        return 0;
      } else {
        const isEdgeSourcesLengthEven = edgesWithSameTargetSourceGridPosition.length % 2 === 0;
        const multiplierBaseOffset = isEdgeSourcesLengthEven ? 0.5 : 1;
        // Get the left part of the targets.
        const leftPart = edgesWithSameTargetSourceGridPosition.slice(
          0,
          Math.floor(edgesWithSameTargetSourceGridPosition.length / 2)
        );
        leftPart.sort((a, b) => b.level - a.level);
        // Get the right part of the targets, removing the middle edge if common target edges length is not even.
        const rightPart = edgesWithSameTargetSourceGridPosition.slice(
          Math.floor(edgesWithSameTargetSourceGridPosition.length / 2) +
            (!isEdgeSourcesLengthEven ? 1 : 0),
          edgesWithSameTargetSourceGridPosition.length
        );

        if (!isEdgeSourcesLengthEven) {
          // If common target edges length is not even, pick the middle one and set its multiplier to 0.
          const middlePoint = edgesWithSameTargetSourceGridPosition.slice(
            Math.floor(edgesWithSameTargetSourceGridPosition.length / 2),
            Math.floor(edgesWithSameTargetSourceGridPosition.length / 2) + 1
          );
          edgesTargetOffset.push({
            groupId: middlePoint[0].groupId,
            multiplier: 0,
          });
        }

        // Set common target edges left and right parts multipliers.
        leftPart.forEach((edge, index) => {
          edgesTargetOffset.push({
            groupId: edge.groupId,
            multiplier: -((index >= 3 ? 3 : index) + multiplierBaseOffset),
          });
        });
        rightPart.forEach((edge, index) => {
          edgesTargetOffset.push({
            groupId: edge.groupId,
            multiplier: (index >= 3 ? 3 : index) + multiplierBaseOffset,
          });
        });

        return edgesTargetOffset.find((edge) => edge.groupId === sourceGroupId)?.multiplier || 0;
      }
    }
  }
};
