import { useQuery, useQueries } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useContext, useEffect } from 'react';
import {
  catchAPIErrorAndLog,
  QueryOptions,
  APIErrorStatus,
  InfiniteQueryOptions,
  useFlatInfiniteQuery,
} from './util';
import {
  getListAssetGroupCloudAssets,
  getListAssetGroups,
  getListAccounts,
  getAccountDetails,
  getListCloudProviders,
  getConnectionStatus,
} from '../axios';

import { ErrorType } from '../../utils/errors';
import { AppContext } from '../../store/store';

export const useGetListAssetGroupCloudAssets = (
  {
    provider,
    accountID,
    groupID,
    ...searchParams
  }: {
    provider: SvcRisksApi.Parameters.Provider | undefined;
    accountID: SvcRisksApi.Parameters.Account | undefined;
    region: SvcRisksApi.Parameters.Region | undefined;
    groupID: SvcRisksApi.Parameters.GroupID;
    limit?: SvcRisksApi.Parameters.LimitParam | undefined;
  },
  options?: InfiniteQueryOptions
) => {
  return useFlatInfiniteQuery(
    ['asset-groups-list-assets', provider, accountID, groupID, searchParams],
    ({ signal, pageParam: cursor }) =>
      getListAssetGroupCloudAssets(
        { provider, accountID, groupID },
        { ...searchParams, cursor: cursor as number },
        { signal }
      ).catch(
        catchAPIErrorAndLog(
          "Error fetching list asset-group's cloud assets[]",
          ErrorType.ASSET_GROUPS_LIST_ASSETS
        )
      ),
    (page) => page?.data.assets,
    {
      ...options,
      enabled: !!provider && !!accountID && !!groupID && !!searchParams.region,
    }
  );
};

export const useGetListAssetGroups = (
  {
    provider,
    accountID,
    ...searchParams
  }: {
    provider: SvcRisksApi.Parameters.Provider | undefined;
    accountID: SvcRisksApi.Parameters.Account | undefined;
    region: SvcRisksApi.Parameters.Region | undefined;
    graphType?: SvcRisksApi.Parameters.GraphType | undefined;
  },
  options?: QueryOptions
) => {
  const queryResult = useQuery<
    AxiosResponse<SvcRisksApiPaths.ListAssetGroups.Responses.$200 | undefined>,
    APIErrorStatus
  >({
    queryKey: ['asset-groups', provider, accountID, searchParams],
    queryFn: ({ signal }) =>
      getListAssetGroups({ provider, accountID }, searchParams, { signal }).catch(
        catchAPIErrorAndLog('Error fetching list asset groups[]', ErrorType.ASSET_GROUPS)
      ),
    ...options,
    enabled: !!provider && !!accountID && !!searchParams.region,
  });

  // Side effect for setting the last updated time in the global store
  const [{}, dispatchGlobalStoreEvent] = useContext(AppContext);
  const lastUpdatedTime = queryResult?.data?.data?.lastUpdated;
  useEffect(() => {
    dispatchGlobalStoreEvent({
      type: 'CHANGE_ACCOUNT_LAST_UPDATED_TIME',
      payload: lastUpdatedTime,
    });
  }, [dispatchGlobalStoreEvent, lastUpdatedTime]);

  // Side effect for setting the loading state in the global store
  useEffect(() => {
    dispatchGlobalStoreEvent({
      type: 'SET_GRAPH_LOADING',
      payload: queryResult.isFetching,
    });
  }, [dispatchGlobalStoreEvent, queryResult.isFetching]);

  return queryResult;
};

export const useGetListCloudProviders = (options?: QueryOptions) => {
  return useQuery<
    AxiosResponse<SvcRisksApiPaths.ListCloudProviders.Responses.$200>,
    APIErrorStatus
  >({
    queryKey: ['cloud-providers'],
    queryFn: ({ signal }) =>
      getListCloudProviders({ signal }).catch(
        catchAPIErrorAndLog('Error fetching list cloud providers[]', ErrorType.CLOUD_PROVIDERS)
      ),
    ...options,
  });
};

export const useGetConnectionStatus = (
  availableProviders: SvcRisksApi.Parameters.Provider[],
  provider: SvcRisksApi.Parameters.Providers,
  options?: QueryOptions
) => {
  const cloudProviders: SvcRisksApi.Parameters.Provider[] | undefined =
    provider === 'All' ? availableProviders : !!provider ? [provider] : [];
  const useConnectionStatusQueries = useQueries({
    queries: cloudProviders.map(
      (cloudProvider) => {
        return {
          queryKey: ['connection-status', cloudProvider],
          queryFn: () =>
            getConnectionStatus(cloudProvider).catch(
              catchAPIErrorAndLog(
                'Error fetching connection status[]',
                ErrorType.CAM_ACCOUNT_STATUS
              )
            ),
        };
      },
      { ...options, enabled: !!provider }
    ),
  });
  const isLoading = useConnectionStatusQueries.some(({ isLoading }) => isLoading);
  const data = isLoading
    ? ({} as Record<string, string>)
    : useConnectionStatusQueries.reduce(
        (providerAccounts, { data }) => {
          if (!data?.data) {
            return providerAccounts;
          }
          const items = [...data.data.items];
          return {
            ...providerAccounts,
            ...items.reduce(
              (acc, item) => {
                const cloudAccountID = 'id' in item ? item.id : item?.subscriptionID;
                return { ...acc, [cloudAccountID]: item.state };
              },
              {} as Record<string, string>
            ),
          };
        },
        {} as Record<string, string>
      );

  return { data: { data }, isLoading };
};

export const useGetListAccounts = (
  pathParams: {
    availableProviders: SvcRisksApi.Parameters.Provider[];
    provider: SvcRisksApi.Parameters.Providers | 'All';
  },
  options?: QueryOptions
) => {
  const cloudProviders: SvcRisksApi.Parameters.Provider[] =
    pathParams.provider === 'All'
      ? pathParams.availableProviders
      : !!pathParams.provider
        ? [pathParams.provider]
        : [];

  const useProviderQueries = useQueries({
    queries: cloudProviders.map(
      (cloudProvider) => {
        return {
          queryKey: ['cloud-provider-accounts', cloudProvider],
          queryFn: () =>
            getListAccounts({ provider: cloudProvider }).catch(
              catchAPIErrorAndLog(
                `Error fetching list accounts[] for ${cloudProvider}`,
                ErrorType.ACCOUNTS
              )
            ),
        };
      },
      { ...options, enabled: !!pathParams.provider }
    ),
  });
  const isLoading = useProviderQueries.some(({ isLoading }) => isLoading);
  const data = isLoading ? [] : useProviderQueries.flatMap(({ data }) => data?.data || []);
  return { data: { data }, isLoading };
};

export const useGetAccountDetails = (
  pathParams: {
    provider: SvcRisksApi.Parameters.Provider | undefined;
    accountID: string | undefined;
  },
  options?: QueryOptions
) => {
  return useQuery<AxiosResponse<SvcRisksApi.Schemas.AccountDetails>, APIErrorStatus>({
    queryKey: ['cloud-provider-accounts', 'account-details', pathParams],
    queryFn: ({ signal }) =>
      getAccountDetails(pathParams, {
        signal,
      }).catch(catchAPIErrorAndLog('Error fetching account details', ErrorType.ACCOUNT_DETAILS)),
    ...options,
    enabled: !!pathParams.provider && !!pathParams.accountID,
  });
};
