import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { QueryOptions, APIErrorStatus, catchAPIErrorAndLog } from './util';
import {
  getCriticalityInfo,
  getAssetDetail,
  getAssetProfile,
  getAssetGraphDefinitions,
  getRiskHistory,
} from '../axios';
import { ErrorType } from '../../utils/errors';

export const useGetCriticalityInfo = (assetID: string | undefined) => {
  return useQuery<AxiosResponse<CriticalityInfoResponse>, APIErrorStatus>({
    queryKey: ['criticalityInfo', assetID],
    queryFn: () => {
      return getCriticalityInfo(assetID).catch(
        catchAPIErrorAndLog('Error fetching criticality info', ErrorType.CRITICALITY_INFO)
      );
    },
    enabled: !!assetID,
  });
};

export const useGetAssetDetail = (
  pathParams: { id: string | undefined },
  options?: QueryOptions
) => {
  return useQuery<AxiosResponse<BigTableCloudAssetDetailResponse>, APIErrorStatus>({
    queryKey: ['big-table', 'asset-detail', pathParams],
    queryFn: ({ signal }) =>
      getAssetDetail(pathParams, { signal }).catch(
        catchAPIErrorAndLog(
          'Error fetching bigtable asset detail',
          ErrorType.BIG_TABLE_ASSET_DETAIL
        )
      ),
    ...options,
    enabled: !!pathParams.id,
  });
};

export const useGetAssetProfile = (
  searchParams: { assetId: string | undefined; assetSubtype?: string },
  options?: QueryOptions
) => {
  // if assetSubtype isn't provided assume we want Cloud Assets
  const defaultSearchParams = { assetSubtype: 'cloudAsset', ...searchParams };
  return useQuery<AxiosResponse<BigTableCloudAssetProfileResponse>, APIErrorStatus>({
    queryKey: ['big-table', 'asset-profile', defaultSearchParams],
    queryFn: ({ signal }) =>
      getAssetProfile(defaultSearchParams, { signal }).catch(
        catchAPIErrorAndLog(
          'Error fetching bigtable asset profile',
          ErrorType.BIG_TABLE_ASSET_PROFILE
        )
      ),
    ...options,
    enabled: !!searchParams.assetId,
  });
};

export const useGetRiskHistory = (
  searchParams: { assetId: string | undefined; assetSubtype?: string },
  options?: QueryOptions
) => {
  // if assetSubtype isn't provided assume we want Cloud Assets
  const defaultSearchParams = { assetSubtype: 'cloudAsset', ...searchParams };
  return useQuery<AxiosResponse<RiskHistoryResponse>, APIErrorStatus>({
    queryKey: ['risk-history', defaultSearchParams],
    queryFn: ({ signal }) =>
      getRiskHistory(defaultSearchParams, { signal }).catch(
        catchAPIErrorAndLog('Error fetching risk history', ErrorType.RISK_HISTORY)
      ),
    ...options,
    enabled: !!searchParams.assetId,
  });
};

export const useGetAssetGraphDefinitions = (options?: QueryOptions) => {
  return useQuery<AxiosResponse<AssetGraphDefinitionsResponse>, APIErrorStatus>({
    queryKey: ['asset-graph-definitions'],
    queryFn: ({ signal }) =>
      getAssetGraphDefinitions({ signal }).catch(
        catchAPIErrorAndLog(
          'Error fetching asset graph definitions',
          ErrorType.ASSET_GRAPH_DEFINITIONS
        )
      ),
    ...options,
  });
};
