import {
  ApiGateway,
  ElastiCache,
  InternetGateway,
  Internet,
  NatGateway,
  Route53,
  Cloud,
  TransitGateway,
  SQS,
  VPCEndpoint,
  DynamoDB,
  Lambda,
  SimpleStorage,
  ElasticContainerService,
  ElasticCloudCompute,
  ElasticBlockStore,
  ElasticFileSystem,
  ElasticContainerRegistry,
  ElasticKubernetesService,
  DocumentDB,
  RelationalDatabaseService,
  NeptuneGraphDatabase,
  ElasticLoadBalancer,
  ManagedStreamingApacheKafka,
  IdentityAccessManagement,
  DAX,
  RdsInstance,
  RdsDbCluster,
  ElasticNetworkInterface,
} from '../assets/Icons/awsServices';
import {
  ApplicationGateway,
  CosmosDB,
  KeyVault,
  LoadBalancer,
  SQL_DB,
  PrivateEndpoint,
  SQLServer,
  StorageAccount,
  AzureVirtualMachine,
  AzureVirtualMachineDisk,
  VirtualMachineScaleSets,
  BlobStorage,
  NATGateway,
  AKSCluster,
  RedisCache,
  APIManagementAPI,
  AppServiceAppFunction,
} from '../assets/Icons/azureServices';
import {
  CloudAPIGateway,
  CloudFunction,
  CloudLoadBalancing,
  CloudStorage,
  CloudVPC,
  CloudSQLInstance,
  ComputeEngineVirtualMachine,
  ComputeEngineInstanceGroups,
  ComputeEnginePersistentDisks,
  GKE,
} from '../assets/Icons/gcpServices';
import {
  ContainerCluster,
  DataStorage,
  Database,
  Identity,
  Serverless,
  VirtualMachine,
} from '../assets/Icons/categories';
import SecurityGroup from '../assets/Icons/security-group.svg?react';

export type CloudAssetIconProps = React.SVGProps<SVGSVGElement> & {
  serviceType?: string;
  serviceName?: string;
};

const CloudAssetIcon = (props: CloudAssetIconProps) => {
  const { serviceType, serviceName, ...otherProps } = props;

  const getRDSIcon = ({ serviceName, ...otherProps }: CloudAssetIconProps) => {
    switch (serviceName) {
      case 'RDS Instance':
      case 'RDS Reserved Instance':
        return <RdsInstance {...otherProps} />;
      case 'RDS DB Cluster':
        return <RdsDbCluster {...otherProps} />;
      default:
        return <RelationalDatabaseService {...otherProps} />;
    }
  };

  /***
  Reference wiki - Supported asset types for each cloud provider:
    https://trendmicro.atlassian.net/wiki/spaces/V1CASRM/pages/610207806/REX+Supported+Resource+Types+-+Asset+types+that+will+have+node+displayed+in+the+frontend+for+the+user.
  ***/
  switch (serviceType) {
    case 'AGW':
      return <ApiGateway {...otherProps} />;
    case 'S3':
      return <SimpleStorage {...otherProps} />;
    case 'ElastiCache':
      return <ElastiCache {...otherProps} />;
    case 'Internet':
      return <Internet {...otherProps} />;
    case 'IGW':
      return <InternetGateway {...otherProps} />;
    case 'ELB':
    case 'ALB':
    case 'CLB':
    case 'NLB':
      return <ElasticLoadBalancer {...otherProps} />;
    case 'NAT':
      return <NatGateway {...otherProps} />;
    case 'ENI':
      return <ElasticNetworkInterface {...otherProps} />;
    case 'R53Resolver':
      return <Route53 {...otherProps} />;
    case 'TGWA':
    case 'TGW':
      return <TransitGateway {...otherProps} />;
    case 'SQS':
      return <SQS {...otherProps} />;
    case 'VPCEndpoint':
      return <VPCEndpoint {...otherProps} />;
    case 'DynamoDB':
      return <DynamoDB {...otherProps} />;
    case 'RDS_Cluster':
    case 'RDS':
      return getRDSIcon({ serviceName, ...otherProps });
    case 'DocumentDB':
      return <DocumentDB {...otherProps} />;
    case 'Neptune':
      return <NeptuneGraphDatabase {...otherProps} />;
    case 'Lambda':
      return <Lambda {...otherProps} />;
    case 'ECS':
      return <ElasticContainerService {...otherProps} />;
    case 'EC2':
      return <ElasticCloudCompute {...otherProps} />;
    case 'EKS':
      return <ElasticKubernetesService {...otherProps} />;
    case 'EBS':
      return <ElasticBlockStore {...otherProps} />;
    case 'EFS':
      return <ElasticFileSystem {...otherProps} />;
    case 'ECR':
      return <ElasticContainerRegistry {...otherProps} />;
    case 'IAM':
      return <IdentityAccessManagement {...otherProps} />;
    case 'MSK':
      return <ManagedStreamingApacheKafka {...otherProps} />;
    case 'DAX':
      return <DAX {...otherProps} />;
    // Azure
    case 'Microsoft.Network/applicationGateways':
      return <ApplicationGateway {...otherProps} />;
    case 'Microsoft.DocumentDB/databaseAccounts':
      return <CosmosDB {...otherProps} />;
    case 'Microsoft.KeyVault/vaults':
      return <KeyVault {...otherProps} />;
    case 'Microsoft.Network/loadBalancers':
      return <LoadBalancer {...otherProps} />;
    case 'Microsoft.Sql/servers/databases':
      return <SQL_DB {...otherProps} />;
    case 'Microsoft.Network/privateEndpoints':
      return <PrivateEndpoint {...otherProps} />;
    case 'Microsoft.Sql/servers':
      return <SQLServer {...otherProps} />;
    case 'Microsoft.Storage/storageAccounts':
      return <StorageAccount {...otherProps} />;
    case 'Microsoft.Compute/virtualMachines':
      return <AzureVirtualMachine {...otherProps} />;
    case 'Microsoft.Compute/disks':
      return <AzureVirtualMachineDisk {...otherProps} />;
    case 'Microsoft.Compute/virtualMachineScaleSets':
      return <VirtualMachineScaleSets {...otherProps} />;
    case 'Microsoft.Storage/storageAccounts/blobServices/containers':
      return <BlobStorage {...otherProps} />;
    case 'Microsoft.Network/natGateways':
      return <NATGateway {...otherProps} />;
    case 'Microsoft.ContainerService/ManagedClusters':
      return <AKSCluster {...otherProps} />;
    case 'Microsoft.Cache/Redis':
      return <RedisCache {...otherProps} />;
    case 'Microsoft.ApiManagement/service':
      return <APIManagementAPI {...otherProps} />;
    case 'Microsoft.ApiManagement/service/apis':
      return <APIManagementAPI {...otherProps} />;
    case 'Microsoft.Web/sites':
      return <AppServiceAppFunction {...otherProps} />;
    // GCP
    case 'ApiGateway Gateway':
      return <CloudAPIGateway {...otherProps} />;
    case 'Cloud Functions':
      return <CloudFunction {...otherProps} />;
    case 'CloudLoadBalancing Backend':
      return <CloudLoadBalancing {...otherProps} />;
    case 'Cloud Storage Buckets':
      return <CloudStorage {...otherProps} />;
    case 'CloudVPC FirewallRule':
      return <CloudVPC {...otherProps} />;
    case 'CloudSQL Instances':
      return <CloudSQLInstance {...otherProps} />;
    case 'ComputeEngine VirtualMachine':
      return <ComputeEngineVirtualMachine {...otherProps} />;
    case 'Compute Engine Instance Groups':
      return <ComputeEngineInstanceGroups {...otherProps} />;
    case 'ComputeEngine Persistent Disks':
      return <ComputeEnginePersistentDisks {...otherProps} />;
    case 'GKE Cluster':
      return <GKE {...otherProps} />;
    // Categories
    case 'Container Cluster':
      return <ContainerCluster {...otherProps} />;
    case 'Data Storage':
      return <DataStorage {...otherProps} />;
    case 'Database':
      return <Database {...otherProps} />;
    case 'Identity':
      return <Identity {...otherProps} />;
    case 'Serverless':
      return <Serverless {...otherProps} />;
    case 'Virtual Machine':
      return <VirtualMachine {...otherProps} />;
    // misc
    case 'SG':
      return <SecurityGroup {...otherProps} />;
    case 'Microsoft.Network/networkSecurityGroups':
      return <SecurityGroup {...otherProps} />;
    default:
      return <Cloud {...otherProps} />;
  }
};

export default CloudAssetIcon;
