import { useState, useRef, useMemo } from 'react';
import {
  Menu,
  MenuItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Flex,
  useTheme,
} from '@tonic-ui/react';
import { useTranslation } from 'react-i18next';

import CloudAssetsGraphNode from './CloudAssetsGraphNode';
import {
  getGraphConstants,
  getGroupNodeWidth,
  getStrokeWidthByZoomValue,
  minZoomLevel,
  ZoomLevel,
  graphBGColorCode,
  tooltipBGColorCode,
  getParentNodeLabelHeight,
} from '../helpers';
import { useCloudAssetsGraphContext } from '../useCloudAssetsGraphContext';
import GroupPopover from '../GraphComponents/GroupPopover';

export type CloudAssetsGraphNodeGroupProps = {
  x: number;
  y: number;
  nodes: GraphNode[];
  r: number;
  onGroupNodeClick?: (groupID: number | undefined) => void;
  group: SvcRisksApi.Schemas.AssetGroup;
  zoomLevel: number;
  secondaryLinkList: SvcRisksApi.Schemas.Link[];
  primaryLinkList: SvcRisksApi.Schemas.Link[];
  securityGroupLinkList: SvcRisksApi.Schemas.Link[];
};

const CloudAssetsGraphNodeGroup = (props: CloudAssetsGraphNodeGroupProps) => {
  const {
    x,
    y,
    nodes,
    r,
    onGroupNodeClick,
    group,
    zoomLevel,
    secondaryLinkList,
    primaryLinkList,
    securityGroupLinkList,
  } = props;

  const theme = useTheme();
  const { colors } = theme;
  const { t } = useTranslation();

  const hoverTimer = useRef<number>();

  const groupLabel =
    group.groupLabel === 'AWS Managed'
      ? t('awsManaged')
      : group.groupLabel === 'Additional discovered assets'
        ? t('additionalDiscoveredAssets')
        : group.groupLabel;
  const { nodeHeight, groupNodePadding, itemsPerColumn } = getGraphConstants(r);

  const zoomedLabelTextHeight = getParentNodeLabelHeight(zoomLevel);

  const n = nodes.length;
  const groupNodeWidth = getGroupNodeWidth(r, n);
  const groupNodeHeight =
    n < itemsPerColumn
      ? 2 * groupNodePadding + nodeHeight * n + zoomedLabelTextHeight / 2
      : 2 * groupNodePadding + itemsPerColumn * nodeHeight + zoomedLabelTextHeight / 2;

  const findUniqueTypesInChildNodes = (childNodes: SvcRisksApi.Schemas.AssetGroup[]): number => {
    const uniqueServiceTypes = childNodes.reduce(
      (accumulator: string[], currentValue: SvcRisksApi.Schemas.AssetGroup) =>
        !accumulator.includes(currentValue.serviceType)
          ? accumulator.concat(currentValue.serviceType)
          : accumulator,
      []
    );
    return uniqueServiceTypes.length;
  };

  const uniqueAssetTypes = findUniqueTypesInChildNodes(nodes);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isGroupNodeHovered, setIsGroupNodeHovered] = useState(false);

  const onGroupNodeMouseEnter = () => {
    if (!isTooltipOpen) {
      setIsTooltipOpen(true);
    }
    setIsGroupNodeHovered(true);
  };

  const onGroupNodeMouseLeave = () => {
    setIsTooltipOpen(false);
    setIsGroupNodeHovered(false);
  };

  const contextMenuWidth = 160;
  const contextMenuMaxHeight = 340;

  const {
    contextMenuNodeId,
    onContextMenuNodeChange,
    onHoveredGroupIdChange,
    activeGroupId,
    activePrimaryLinks,
    isolatedNodeIsActive,
    activeSGLinks,
    securityGroupNodeId,
  } = useCloudAssetsGraphContext();
  const isContextMenuOpen = contextMenuNodeId === group.groupID;
  // TODO: Reinstate context menu once we integrate the rename group API.
  // const onContextMenuOpen = () => {
  //   if (contextMenuNodeId !== group.groupID) {
  //     onContextMenuNodeChange(group.groupID);
  //     setIsTooltipOpen(false);
  //   }
  // };

  const onContextMenuClose = () => {
    onContextMenuNodeChange(undefined);
  };

  const ContextMenu = () => (
    <Menu
      width={contextMenuWidth}
      maxHeight={contextMenuMaxHeight}
      overflow="auto"
      position="static"
      isOpen={isContextMenuOpen}
      onClose={onContextMenuClose}
    >
      <MenuItem onClick={() => {}}>
        <Text fontSize="sm" lineHeight="5x">
          PLACEHOLDER
        </Text>
      </MenuItem>
    </Menu>
  );

  const groupNodes = nodes.map((node, index) => {
    return (
      <CloudAssetsGraphNode
        x={node.x}
        y={minZoomLevel(ZoomLevel.Wide, zoomLevel) ? node.y : node.y + 16}
        r={r}
        text={node.groupLabel}
        serviceType={node.serviceType}
        serviceName={node.serviceName}
        key={index}
        riskiestAssetScore={node.riskiestAssetScore}
        highRiskAssetCount={node.highRiskAssetCount}
        totalAssets={node.numberOfAssets}
        onGroupNodeClick={onGroupNodeClick}
        groupId={node.groupID}
        zoomLevel={zoomLevel}
        isChildren={true}
        parentGroupId={group.groupID}
        secondaryLinkList={secondaryLinkList}
        primaryLinkList={primaryLinkList}
        securityGroupLinkList={securityGroupLinkList}
        securityGroupsCount={node.numberOfSecurityGroups}
      />
    );
  });

  const isActiveSubnet = useMemo(() => {
    if (!activeGroupId) return true;

    const isConnectedViaPrimaryLink = groupNodes.some((node) =>
      activePrimaryLinks?.some(
        (link: SvcRisksApi.Schemas.Link) =>
          link.sourceGroupID === node.props.parentGroupId ||
          link.targetGroupID === node.props.parentGroupId
      )
    );

    const isConnectedViaSecurityGroupLink =
      activeGroupId === securityGroupNodeId &&
      groupNodes.some((node) =>
        activeSGLinks?.some(
          (link: SvcRisksApi.Schemas.Link) =>
            link.sourceGroupID === node.props.groupId || link.targetGroupID === node.props.groupId
        )
      );

    return isConnectedViaPrimaryLink || isConnectedViaSecurityGroupLink || isolatedNodeIsActive;
  }, [
    groupNodes,
    activeGroupId,
    activePrimaryLinks,
    isolatedNodeIsActive,
    activeSGLinks,
    securityGroupNodeId,
  ]);

  return (
    <g
      style={{ cursor: 'default' }}
      onMouseEnter={() => {
        window.clearTimeout(hoverTimer.current);
        hoverTimer.current = window.setTimeout(() => {
          onHoveredGroupIdChange(group.groupID);
        }, 250);
      }}
      onMouseLeave={() => {
        window.clearTimeout(hoverTimer.current);
        onHoveredGroupIdChange(undefined);
      }}
    >
      <GroupPopover
        isShown={isTooltipOpen}
        groupLabel={group.groupLabel}
        highestRiskScore={group.riskiestAssetScore}
        uniqueAssetTypesCount={uniqueAssetTypes}
      >
        <g>
          <rect
            width={groupNodeWidth}
            height={groupNodeHeight}
            x={x - groupNodeWidth / 2}
            y={y - groupNodeHeight / 2}
            rx={15}
            fill={colors[isGroupNodeHovered ? tooltipBGColorCode : graphBGColorCode]}
            stroke={isActiveSubnet ? colors['gray:60'] : colors['gray:90']}
            strokeWidth={getStrokeWidthByZoomValue(zoomLevel)}
            pointerEvents="all"
          ></rect>
        </g>
      </GroupPopover>

      <foreignObject
        width={groupNodeWidth}
        x={x - groupNodeWidth / 2}
        height={zoomedLabelTextHeight}
        y={y - groupNodeHeight / 2 - zoomedLabelTextHeight / 2}
      >
        <Flex height={zoomedLabelTextHeight} justifyContent="center">
          <Flex
            paddingX="2x"
            borderRadius="8px"
            backgroundColor={graphBGColorCode}
            height={zoomedLabelTextHeight}
            alignItems="center"
            justifyContent="center"
          >
            {minZoomLevel(ZoomLevel.Medium, zoomLevel) ? (
              <Text
                color={isActiveSubnet ? colors['white:emphasis'] : colors['gray:60']}
                fontSize="xs"
                maxWidth={groupNodeWidth - 34}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {groupLabel}
              </Text>
            ) : (
              <Text
                color={isActiveSubnet ? colors['white:emphasis'] : colors['gray:60']}
                fontSize="4xl"
                maxWidth={groupNodeWidth - 34}
                overflow="hidden"
                textOverflow="ellipsis"
                lineHeight="2.2rem"
              >
                {group.serviceType}
              </Text>
            )}
          </Flex>
        </Flex>
      </foreignObject>

      <Popover isOpen={isContextMenuOpen} onClose={onContextMenuClose} placement="left">
        <PopoverTrigger>
          {/* Area for context menu since the onContextMenu event is not available on svg elements */}
          <foreignObject
            x={x - groupNodeWidth / 2}
            y={y - groupNodeHeight / 2}
            width={groupNodeWidth}
            height={groupNodeHeight}
            // TODO: Reinstate context menu once we integrate the rename group API.
            // onContextMenu={(e) => {
            //   e.preventDefault();
            //   onContextMenuOpen();
            // }}
            onMouseEnter={onGroupNodeMouseEnter}
            onMouseLeave={onGroupNodeMouseLeave}
          />
        </PopoverTrigger>
        <PopoverContent width={contextMenuWidth} padding={0} PopperProps={{ usePortal: true }}>
          <ContextMenu />
        </PopoverContent>
      </Popover>

      {groupNodes}
    </g>
  );
};

CloudAssetsGraphNodeGroup.displayName = 'CloudAssetsGraphNodeGroup';
export default CloudAssetsGraphNodeGroup;
