import React, { PropsWithChildren } from 'react';
import { TonicProvider, ToastManager, Flex } from '@tonic-ui/react';
import './i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Global, CacheProvider, css } from '@emotion/react';
import createCache from '@emotion/cache';
import RiskExplorer from './views/RiskExplorer';
import ErrorBoundary from './components/error/ErrorBoundary';
import GeneralErrorOverlay from './components/error/GeneralErrorOverlay';
import AWSRumInitialize from './rum/aws-rum';
import AppStore from './store/store';

const LocalAppLayout = (props: PropsWithChildren) => {
  const isPaddingEnabled = localStorage.getItem('ADD_PADDING') === 'true';
  return (
    <>
      <Global
        styles={css`
          html,
          body,
          #root {
            height: 100%;
          }
          body {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin: 0;
            background-color: #151515;
            font-family:
              -apple-system,
              BlinkMacSystemFont,
              Segoe UI,
              Roboto,
              Oxygen,
              Ubuntu,
              Cantarell,
              Fira Sans,
              Droid Sans,
              Helvetica Neue,
              sans-serif;
          }
        `}
      />
      <Flex
        direction="column"
        fontSize="sm"
        lineHeight="sm"
        height="100vh"
        {...(isPaddingEnabled
          ? {
              paddingX: '4x',
              paddingLeft: '20x',
              // SASE portal UX + V1 header height
              paddingTop: 227 + 48,
              paddingBottom: '10px',
            }
          : { paddingX: '4x' })}
      >
        <Flex
          direction="column"
          flex="1"
          width="100%"
          height="100%"
          position="relative"
          {...props}
        />
      </Flex>
    </>
  );
};

const EmotionCacheProvider = ({ children, nonce }: PropsWithChildren<{ nonce: string }>) => {
  const cache = createCache({ key: 'tonic-ui', nonce });
  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

// NOTE: Use this to pass props from the host app
export default function App(/* props?: MicroAppProps */) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
        staleTime: 1000 * 60 * 5,
        gcTime: 1000 * 60 * 5,
      },
    },
  });

  // nonce has no significance. Just something we made up. Eventually should be set to match a value from the CSP of V1
  const nonce = 'dj2dddaa9wD6';

  if (import.meta.env.MODE === 'production') {
    AWSRumInitialize();
  }

  const app = (
    <EmotionCacheProvider nonce={nonce}>
      <TonicProvider
        useCSSBaseline={import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test'}
        colorMode={{
          defaultValue: 'dark',
        }}
      >
        <AppStore>
          {import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test' ? (
            <LocalAppLayout>
              <ErrorBoundary ErrorComponent={GeneralErrorOverlay}>
                <ToastManager>
                  <QueryClientProvider client={queryClient}>
                    <RiskExplorer />
                    <ReactQueryDevtools initialIsOpen={false} />
                  </QueryClientProvider>
                </ToastManager>
              </ErrorBoundary>
            </LocalAppLayout>
          ) : (
            <ErrorBoundary ErrorComponent={GeneralErrorOverlay}>
              <ToastManager>
                <QueryClientProvider client={queryClient}>
                  <RiskExplorer />
                </QueryClientProvider>
              </ToastManager>
            </ErrorBoundary>
          )}
        </AppStore>
      </TonicProvider>
    </EmotionCacheProvider>
  );
  if (import.meta.env.MODE === 'development') {
    // NOTE: <StrictMode /> is only needed for development
    //https://react.dev/reference/react/StrictMode
    return <React.StrictMode>{app}</React.StrictMode>;
  }

  return app;
}
